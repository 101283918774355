import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../layouts/index"
import Seo from "../components/seo"

//Page content
import LandingContent from "../components/landing"
import Brochure from "../components/brochure"

const COVID19Page = (props) => {

  const data = useStaticQuery(graphql`
  query COVIDQuery {
    contentfulPage(slug: {eq: "/COVID-19/"}) {
      title
      description {
        description
      }
      pageContent {
        ... on ContentfulCommercialBrochure {
          __typename
          id
          commercialBrochureLink {
            contentful_id
            file {
              url
            }
          }
          commercialTagline
        }
        ... on ContentfulInformationNotesList {
            __typename
            id
            informationNotes {
              contentful_id
              title
              informationFile {
                file {
                    url
                    fileName
                    contentType
                  }
              }
            }
          }
        ... on ContentfulLandingContent {
            __typename
            id
            title
            useCaseSectionTitle
            description {
                childMarkdownRemark {
                    html
                }
            }
            useCasesList {
                id
                title
                description {
                    childMarkdownRemark {
                        html
                    }
                }
            }
          }
      }
    }
  }
  `)

  // const commercialBrochure = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulCommercialBrochure");
  const landingContent = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulLandingContent");
  const informationNotes = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulInformationNotesList");

  return (
    <Layout>
      <Seo
        title={data.contentfulPage.title}
        description={data.contentfulPage.description.description}
        keywords={data.contentfulPage.keywords} />
      <LandingContent content={landingContent} />
      <div className="flex flex-col w-full align-items common-wrapper mb-14">
        <ul className="text-align-center">
          {
            informationNotes.informationNotes.map((note, index) => {
              return (
                <li key={note.id} className="my-8 ">
                  <span className="f3 font-bold block">{note.title}</span>
                  <a className="button bg-blue-dafinity text-white my-4" target="_blank" rel="noopener noreferrer" href={note.informationFile.file.url}>{`Guide pratique n° ${index+1}`}</a>
                </li>)
            })
          }
        </ul>
      </div>
    </Layout>
  )
}

export default COVID19Page